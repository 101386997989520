.infrastructure-main-card {
    padding-bottom: 70px;
    position: relative;

    .header-bar {
        position: relative;

        .box-circle-r {
            height: 100%;
            width: 260px;
            background: #eff0f0;
            position: absolute;
            right: -7%;
            top: 0;
            border-radius: 100%;
        }

    }

    .tab-for-graph {

        .map-heading-map {
            span {
                background: #EBE9FA;
                font-size: 13px;
                padding: 8px 10px;
                border-radius: 50px;
                color: #113689;
                font-weight: 600;
                white-space: nowrap;

                @media (max-width:1399px) and (min-width: 1024px) {
                    font-size: 12px !important;
                    padding: 6px 10px;
                }
            }
        }

        .heading-sm1 {
            letter-spacing: 0.14px;
            color: #113689;
            font-size: 23px;
            font-weight: 600;
            margin: 15px 15px 0 0;

            @media (max-width:1599px) and (min-width: 1400px) {
                font-size: 18px !important;
            }

            @media (max-width:1399px) and (min-width: 1024px) {
                font-size: 15px !important;
            }
        }

        .nav-tabs .nav-link {
            color: #212121;
            font-size: 14px;
            padding: 7px 15px;
            background: #ffffff;
            margin-bottom: 0px;
            border: 1px solid #ffffff;
            margin-right: 0px;

            @media (max-width:1599px) and (min-width: 1361px) {
                font-size: 12px;
                padding: 7px 12px;
            }

            @media (max-width:1360px) and (min-width: 1024px) {
                font-size: 10px;
                padding: 5px 10px !important;
            }

        }

        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
            color: #113689;
            background-color: #EBE9FA;
            border-color: #EBE9FA;
            font-weight: 600;
            box-shadow: 0px 11px 14px rgb(255 255 255 / 12%);
            margin: 0 2px;
        }

        .customize-btn {
            background: #E2E2E2;
            color: #000;
            font-weight: 600;
            font-size: 15px;
            padding: 8px 20px;
            float: right;
            margin-top: 15px;
            z-index: 9999;
            position: sticky !important;

            @media (max-width:1599px) and (min-width: 1361px) {
                font-size: 14px;
                margin-top: 12px;
            }

            @media (max-width:1360px) and (min-width: 1024px) {
                font-size: 12px;
                margin-top: 7px;
                padding: 7px 15px;
            }

            span.material-icons-round {
                font-size: 20px;
                margin-right: 5px;
                color: #254895;
            }
        }

        .tab-text-infra.download-rep {
            display: inline-flex;
            align-items: center;
            cursor: pointer;

            span.material-icons-round {
                border: 1px solid #444;
                border-radius: 10px;
                padding: 4px;
                font-size: 20px;
                margin-left: 5px;
            }
        }


        .report-inner-tab {
            .nav-tabs {
                background: #F6F7F8;
                display: inline-flex;
                padding: 6px 10px;
                border-radius: 40px;

                @media (max-width:1360px) and (min-width: 1024px) {
                    padding: 4px 9px !important;
                }
            }

            .nav-tabs .nav-link {
                // background: #fff;
                // border: 1px solid #fff;
                background: transparent;
                border: 1px solid transparent;
                margin-bottom: 0px;
                padding: 7px 15px;
            }

            .nav-tabs .nav-item.show .nav-link,
            .nav-tabs .nav-link.active {
                color: #113689;
                background-color: #EBE9FA;
                border-color: #EBE9FA;
                font-weight: 600;
            }


            .table-brud-card {
                position: relative;

                .nav-absolute.nav-tabs {
                    position: absolute;
                    top: -1px;
                    right: 12px
                }

                .tab-content {
                    margin-top: 59px;

                    @media (max-width: 1599px) and (min-width: 1361px) {
                        margin-top: 53px;
                    }

                    @media (max-width:1360px) and (min-width: 1024px) {
                        margin-top: 40px;
                    }

                }

                h4.brudcrumb_heading {
                    position: absolute;
                    top: 12px;
                    left: 12px;
                   
                    @media (max-width:1599px) and (min-width: 1400px) {                        
                        top: 9px;
                    }
            
                    @media (max-width:1399px) and (min-width: 1024px) {                        
                        top: 4px;
                    }
                }
            }

        }

        .about-card {
            background: #fff;
            padding: 25px;
            border-radius: 15px;
        }

        .heading-sm2 {
            font-size: 24px;
        }

        .btn-add-more {
            background: #F7EDD6;
            color: #FABF38;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: 30px;
            border-radius: 5px;
            cursor: pointer;
        }

        table tr th {
            color: #212121 !important;
            font-weight: 600 !important;
            padding: 12px 15px !important;
            white-space: nowrap;

        }

        .text-blue {
            color: #254895;
        }

        .heading-sm.heading-sm2 {
            font-size: 20px;
            display: flex;
            align-items: center;

            @media (max-width:1599px) and (min-width: 1024px) {
                font-size: 16px;
            }
        }

        .filter-card {
            background: #fff;
            padding: 15px 20px 20px;
            border-radius: 20px;

            input.form-control.border-only-bottom {
                border: 0px;
                border-bottom: 1px solid #b2b1b1 !important;
                border-radius: 0;
                padding-left: 0;
            }

            .heading-sm1 {
                letter-spacing: 0.14px;
                color: #000000;
                font-size: 18px;
                font-weight: 600;
                margin: 0 15px 0 0;

                @media (max-width:1599px) and (min-width: 1024px) {
                    font-size: 15px;
                }

            }

            .indicator-select {
                label.bg-grey2 {
                    background: transparent;
                    top: 5px;
                    color: #000;

                    @media (max-width:1599px) and (min-width: 1024px) {
                        left: 9px;
                        top: 4px;
                    }
                }

                .form-select {
                    height: 48px;
                    border-radius: 35px;
                    padding-top: 13px;
                    font-weight: 600;
                    appearance: auto;
                    padding-left: 12px;
                    padding-right: 10px;
                    border-right: 10px solid #f2f0f0;
                    cursor: pointer;

                    @media (max-width:1599px) and (min-width: 1024px) {
                        padding-left: 9px;
                        padding-right: 0;
                        height: 41px;
                    }

                }
            }

            button.header-dropdown-btn {
                width: 100%;
                justify-content: center;
                font-size: 16px;
                position: static !important;

                @media (max-width:1599px) and (min-width: 1024px) {
                    font-size: 14px;
                }
            }
        }

        a.action-icon {
            margin-right: 10px;
            border: 1px solid #696969;
            border-radius: 40px;
            vertical-align: middle;
            display: inline-block;
            height: 30px;
            width: 30px;
            text-align: center;
            line-height: 26px;

            img {
                width: 16px;
            }
        }

        .mul-tab-main {
            display: flex;
            align-items: center;
            margin-top: 5px;

            .nav-link {
                padding: 9px 15px !important;
                background: #fff;
                border: 1px solid #fff;
                box-shadow: 0px 11px 14px #9898981F;
            }

            .details-multi {
                display: none;
            }

            .multibtn {
                display: flex;
                padding: 5px 8px;
                background: #fff;
                margin-bottom: 0px;
                border: 1px solid #fff;
                border-radius: 50px;

                .nav-link {
                    margin-bottom: 0px;
                    font-size: 13px;
                    padding: 5px 15px !important;
                    box-shadow: 0px 11px 14px rgb(255 255 255 / 12%);
                }

                .details-multi {
                    display: block;
                }

            }

        }


        .impact-box-content-education {
            background: #faf6ed;
            border-radius: 25px;
            margin-bottom: 20px;
            min-height: 183px;
            padding: 20px 20px;
            position: relative;
            transition: all .3s ease-in-out;

            h2.heading-sm {
                font-size: 20px;
                margin-right: 0;
            }

            .nav-tabs {
                background: #FFFFFF;
                padding: 5px 10px;

                .nav-link.active {
                    color: #fff;
                    background-color: #113689;
                    border-color: #113689;
                }
            }

            .chart-left-text {
                h2 {
                    font-size: 28px;
                    font-weight: 800;

                    @media (max-width: 1599px) and (min-width: 1024px) {
                        font-size: 20px !important;
                    }
                }

                h6 {
                    font-size: 24px;
                    font-weight: 500;
                    margin-bottom: 0;

                    @media (max-width: 1599px) and (min-width: 1024px) {
                        font-size: 20px !important;
                    }
                }
            }

            @media (max-width: 1399px) and (min-width: 1024px) {
                .heading-sm {
                    font-size: 20px !important;
                }
            }

            g.highcharts-label tspan.highcharts-text-outline {
                fill: transparent;
                stroke: transparent;
            }

        }

        .blue-strip {
            background: #113689;
            padding: 10px 0;
            margin-bottom: 10px;
            position: fixed;
            width: 100%;
            z-index: 999;

            .heading-sm1 {
                color: #fff;
                font-size: 20px;
            }
        }

        .blue-strip+.container {
            padding: 65px 12px 11px !important;

            @media (max-width: 1399px) and (min-width: 1024px) {
                padding: 60px 12px 8px !important
            }
        }
        .blue-strip+.container.header-p-extra {
            

            @media (min-width: 1599px)  {
                padding: 74px 12px 11px !important; 
            }
            @media (max-width: 1399px) and (min-width: 1024px) {
                padding: 65px 12px 8px !important
            }

            .mul-tab-main .multibtn .nav-link{                
                @media (max-width: 1399px) and (min-width: 1024px) {
                    font-size: 12px;
                }
            }

        }

        .tab-text-infra {
            font-size: 20px;
            font-weight: bold;
            color: #212121;
            white-space: nowrap;

            @media (max-width:1360px) and (min-width: 1024px) {
                font-size: 14px;
            }
        }

    }

}



.custmize-filter-column {
    position: fixed;
    right: 0;
    bottom: 10%;
    background: #fff;
    min-height: 250px;
    width: 350px;
    padding: 0;
    border-radius: 10px 0px 0px 10px;
    box-shadow: 0 10px 30px #0000001a;
    z-index: 9;
    opacity: 0;
    transform-origin: right;
    transform: rotateY(90deg);
    transition: all 500ms ease-in-out;

    @media (max-width:1599px) and (min-width: 1361px) {
        bottom: 11%;
        width: 300px;

        .search-box-div.mb-3 {
            margin-bottom: 10px !important;
        }
    }

    @media (max-width:1360px) and (min-width: 1024px) {
        bottom: 10%;

        .search-box-div.mb-3 {
            margin-bottom: 7px !important;
        }
    }

    &.show {
        opacity: 1;
        transform: rotateY(0deg);
        transition: all 500ms ease-in-out;
    }

    .form-group.search .form-control {
        background: #F2F2F2;
        height: 50px;
        border-radius: 50px;
        margin-top: 15px;

        @media (max-width:1599px) and (min-width: 1024px) {
            height: 40px;
        }
    }

    .form-group.checkbox {
        background: #F2F2F2;
        margin: 8px 0;
        padding: 6px 10px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        font-size: 14px;

        @media (max-width:1599px) and (min-width: 1361px) {
            margin: 6px 0;
            padding: 6px 10px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            font-size: 12px;
        }

        @media (max-width:1360px) and (min-width: 1024px) {
            margin: 6px 0;
            padding: 6px 10px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            font-size: 12px;
        }

        input {
            margin-right: 10px;
            height: 14px;
            width: 14px;
        }
    }

    button.close-btn {
        background: #ffffff !important;
        height: 35px !important;
        width: 35px !important;
        border: 1px solid #dadada !important;
        border-radius: 50px !important;
        font-size: 10px !important;
        color: #000000 !important;
        font-weight: 600 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }

}

th.report-name-width-50 {
    width: 70%;
}

.ag-theme-custom-height {
    // height: 600px;
    margin-top: 12px;

    @media (max-width: 1399px) and (min-width: 1024px) {
        margin-top: 12px;
    }
}

.ag-header-row.ag-header-row-column,
.ag-header-row.ag-header-row-column-group {
    background: #EBE9FA;
    border-color: #EBE9FA;
    color: #000 !important;
    font-weight: 600 !important;
    padding: 0px 15px !important;
    white-space: nowrap;
    font-size: 13px !important;
}

.ag-header-cell,
.ag-header-group-cell,
.ag-cell {
    padding: 0px 11px !important;
}

button.scroll-right-btn {
    background: #113689 !important;
    color: #fff;
    border: 1px solid #113689 !important;
    padding: 2px 8px;
    margin-top: 15px;
    position: static;
    display: inline-flex;
    align-items: center;
    margin-bottom: -10px;
    border-radius: 20px;
}

.ag-header-cell:hover {
    background: #EBE9FA !important;
}

button.scroll-right-btn .material-icons-round {
    font-size: 60px;
}

button.scroll-right-btn:hover {
    background: #ffffff;
    color: #113689;
}

// .ag-icon {
//     color: #fff !important;
//     cursor: pointer;
// }

button.scroll-right-btn .material-icons-round {
    font-size: 35px;
    line-height: 30px;
}

.ag-floating-bottom-container {
    font-weight: 700;
}

// .ag-floating-bottom-viewport .ag-cell {
//     text-align: right !important;
// }
.pinnedData {
    background: #fff;
    padding: 7px 15px 20px;
    font-weight: 700;
    font-size: 13px;
    border-radius: 0 0 16px 16px;
}

.select-infra.button-group-filter select.form-select {
    background: #EBEBEB;
    height: 40px;
    font-weight: 500;
    border: 0;
    font-size: 16px;
    appearance: auto;
    padding: 8px 15px;
    border-right: 12px solid transparent;

    option {
        background: #fff !important;
    }
}

img.dropicon {
    position: absolute;
    right: 20px;
    top: 13px;
}

.ag-root-wrapper.ag-layout-normal {
    height: 100%;
    border-radius: 16px 16px 0 0;
}

.ag-header-cell::before {
    background-color: #e2e2e200 !important;
}

.ag-row {
    border-bottom: 1px solid #444444 !important;
}

.ag-ltr .ag-cell {
    border-right: 1px solid #444444 !important;
}

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    border: 1px solid transparent !important;
}

.ag-row-animation .ag-row:nth-child(even) {
    background: #FFF;
}


.table-text-i button#uncontrolled-tab-example-tab-table {
    display: flex;
    align-items: center;

    &:before {
        content: "";
        background: url('../../assets/images/table_cells.png');
        width: 15px;
        height: 15px;
        background-size: cover;
        display: block;
        margin-right: 5px;
    }
}

.table-text-i button#uncontrolled-tab-example-tab-graph {
    display: flex;
    align-items: center;

    &:before {
        content: "";
        background: url('../../assets/images/circular-graphic.png');
        width: 15px;
        height: 15px;
        background-size: cover;
        display: block;
        margin-right: 6px;
    }
}

.option-hide {
    height: 0px !important;
    font-size: 0px !important;
    display: none;
}

.right-devider-icon {
    background: #FAFCFF;
    position: absolute;
    bottom: -5%;
    left: 0;
    // width: 300px;
    // height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    z-index: 2;
    padding: 25px;
    display: none;

    img.icon-infra {
        opacity: .5;
    }
}

#webpack-dev-server-client-overlay-div {
    display: none;
}

.rightAligned {
    text-align: left;
}

.scroll-btn-graph {
    display: flex;
    justify-content: center;
    margin: 30px 0 0;
}

.scroll-btn-graph span {
    background: #DBDBDB;
    display: flex;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 34px;
}

.report-1003 g.highcharts-data-labels g.highcharts-label .highcharts-text-outline {
    stroke: transparent;
}

.multi-header-table {
    .ag-header-group-cell-label.ag-sticky-label {
        flex: auto;
        text-align: center;

        .ag-header-group-text {
            width: 100%;
            color: #000;
            font-weight: 600;
            font-size: 14px;
        }
    }

    .ag-header-row.ag-header-row-column {
        .ag-header-cell {
            border-color: #afafaf !important;

            &::before {
                background-color: #afafaf;
            }

            .ag-header-cell-resize::after {
                background-color: #afafaf;
            }
        }
    }

    .ag-header-row.ag-header-row-column-group {
        .ag-header-group-cell {
            border-color: #afafaf !important;

            &::before {
                background-color: #afafaf;
            }

            .ag-header-cell-resize::after {
                background-color: #afafaf;
            }
        }
    }
}

.infrastructure-main-card .tab-for-graph .impact-box-content-education.tab-sdb-blue .nav-tabs .nav-link {
    background: #113689 !important;
    color: #fff;
}




.highcharts-data-table table {
    font-size: 14px;

    @media (max-width:1550px) and (min-width: 1199px) {
        font-size: 9px;
    }

    th {
        font-size: 12px;

        @media (max-width:1550px) and (min-width: 1199px) {
            font-size: 8px;
        }
    }
}



// CSS for 4001 reports

.report-4001 {
    .impact-box-content-education {
        background: #E5F1ED !important;

        &.bg-light-red {
            background: #FFE8E8 !important;

            .highcharts-background {
                fill: #FFE8E8;
            }
        }

        &.bg-light-blue {
            background: #E0E1FE !important;

            .highcharts-background {
                fill: #E0E1FE;
            }
        }
    }

    .highcharts-background {
        fill: #E5F1ED;
    }


    h2.heading-sm {
        font-size: 22px !important;
    }

    span.subfont {
        font-weight: 400;
        font-size: 18px;
    }

    rect.highcharts-button-box {
        fill: transparent;
    }

    .highcharts-data-table table th {
        background: transparent;
    }

    .select-infra.button-group-filter {
        select.form-select {
            background: #fff;
            padding: 5px 0px 5px 6px;
            // max-width: 272px;
            // min-width: 210px;
            border-right: 4px solid transparent;
            cursor: pointer;
        }

        .form-select:focus {
            box-shadow: none;
        }
    }


    .Comparison-box {
        background: #FFFFFF;
        padding: 10px 15px 10px 20px;
        margin: 20px 0;
        border-radius: 10px;

        .sub-heading {
            font-size: 16px;
            margin-bottom: 0;
            font-weight: 500;

            @media (max-width:1599px) and (min-width: 1400px) {
                font-size: 14px;
            }

            @media (max-width:1399px) and (min-width: 1024px) {
                font-size: 12px;
            }
        }

        .Comparison-select-group {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            select.form-select {
                background: #fff;
                padding: 5px 0px 5px 6px;
                appearance: auto;
                width: 19%;
                margin-right: 4px;
                font-size: 14px;

                @media (max-width:1599px) and (min-width: 1400px) {
                    font-size: 12px;
                }

                @media (max-width:1399px) and (min-width: 1024px) {
                    font-size: 10px;
                }

            }

            .form-select:focus {
                box-shadow: none;
            }
        }

    }

    .radio-btn-filter {
        display: flex;

        div {
            margin-left: 14px;
            font-size: 20px;
            font-weight: 500;
            display: flex;
            align-items: center;
        }

        input {
            margin-right: 6px;
            height: 18px;
            width: 18px;
        }
    }

    @media (max-width: 1699px) and (min-width: 1400px) {
        h2.heading-sm {
            font-size: 18px !important;
        }

        .radio-btn-filter div {
            font-size: 18px !important;
        }
    }

}

.report-3013 {
    .graph-filter-drop {
        width: 250px;
        position: absolute;
        right: 23%;
        top: 7px;

        @media (max-width:1599px) and (min-width: 1400px) {
            right: 23%;
            top: 4px;
        }

        @media (max-width:1399px) and (min-width: 1024px) {
            right: 23%;
            top: 1px;
        }

        :where(.css-dev-only-do-not-override-djtmh8).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector{
            border-radius: 20px;
            height: 35px;

            @media (max-width:1399px) and (min-width: 1024px) {
               height: 30px;
               font-size: 13px;
            }
        }

        .ant-select-selector{border:1px solid #ddd !important; box-shadow: none !important;}
    }
}
.tab-content-reduce{
    .tab-content{margin-top: 20px !important;}
}