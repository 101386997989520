::ng-deep #bmap_gc {
  margin-top: -30px !important;
}

::ng-deep .esriPopup .titleButton {
  display: none !important;
}
.graph-wrap {
  .highcharts-yaxis-labels,
  .highcharts-xaxis-labels{
      text{
          font-size: 0.75em;
          letter-spacing: 0px;
          color: #7E84A3 !important;
          font-family: 'Poppins', sans-serif;
      }
  }
  .highcharts-legend{
      transform: translate(-10px, 10px);
  }
  .highcharts-credits {
      display: none;
  }
  .highcharts-tooltip{
    span {
      font-family: 'Poppins', sans-serif !important;
    }
  }
  .highcharts-container{
    width: 100%;
  }
  .highcharts-button {
    display: none !important; /* This will hide all buttons, including the hamburger menu */
    z-index: -1;
}
  .tooltipMainWrapper {
      display: block;
      min-width: 260px;
      .tooltipHeader{
        color: #D94148;
        font-weight: 500;
        padding: 10px;
        font-size: 13px;
        display: block;
        border-bottom: 1px solid #dddddd;
        width: 100%;
      }
      .tooltipPointContent{
        padding: 10px;
        border-bottom: 1px solid #dddddd;
      }
      .tooltipPointWrapper {
        // display: block;
        margin: 0;
        span{
          padding: 0 10px 0 30px;
          display: block;
        }
      }
      .tooltipPoint {
        font-size: 12px;
        letter-spacing: 0px;
        color: #131523;
        font-weight: 600;
        position: relative;
      }
      .tooltipLine {
        display: block;
        opacity: 1;
        background-color: #dddddd;
        width: 100%;
        height: 1px;
        padding: 0px !important;
        margin: 10px 0;
      }
      .tooltiono{
        font-size: 12px;
        color: #7E84A3;
      }
      .tooltipPointIcon {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translate(-50%, -50%);
        // border: 2px solid #fff;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        display: block;
        &.darkblue{
          background: #0000FF;
        }
        &.blue{
          background: #5050FF;
        }
        &.lightblue{
          background: #7D7DFF;
        }
        &.darkgreen{
          background: #009632;
        }
        &.lightgreen{
          background: #96FF96;
        }
        &.yellow{
          background: #FFFF00;
        }
        &.orange{
          background: #FFC800;
        }
        &.lightpink{
          background: #FA9696;
        }
        &.pink{
          background: #FA6464;
        }
        &.red{
          background: #FA4B4B;
        }
      }
  }
    
  .statetooltipWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    padding: 15px 0;
    .statetooltipPoint{
      font-size: 1em;
      letter-spacing: 0.09px;
      color: #5A607F;
      font-weight: 400;
      margin: 0 15px;
      span {
        padding: 0;
        letter-spacing: 0.07px;
        color: #131523;
        font-size: 1.2em;
        font-weight: 500;
    }
  }
}
}
.loader-center {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-40%, -40%);
  margin: 0 auto;
  z-index: 9;
  background: #000;
  height: 100%;
  width: 100%;
  opacity: 0.5;
}


.spinner-border {
  z-index: 99999 !important;
  color: #fff;
  opacity: 1;
}

.esri-map-draw {
  height: 900px;
  width: 100%;
}

g#bmap_graphics_layer {
  transform: translate(-50px, -150px);
}

div#dijit_Dialog_0 {
  display: none;
}

.show-color-meaning {
  display: flex;
  justify-content: end;
  gap: 5px;
  padding-right: 40px;

  @media (max-width: 1599px) and (min-width: 1400px) {
    padding-right: 0px;
  }

  @media (max-width: 1399px) and (min-width: 1024px) {
    padding-right: 0px;
    position: absolute !important;   
    bottom: 2% !important;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: start;
  }

}

.show-color-first {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-left: 10px;
  white-space: nowrap;

  @media (max-width: 1599px) and (min-width: 1400px) {
    margin-left: 5px;
    font-size: 14px;
  }

  @media (max-width: 1399px) and (min-width: 1024px) {
    margin-left: 5px;
    font-size: 10px;
  }

}

.circle1 {
  width: 40px;
  height: 15px;
  background-color: #fcae91;
  border-radius: 0;

}

.circle2 {
  width: 40px;
  height: 15px;
  background-color: #ffeda0;
  border-radius: 0%;

}

.circle3 {
  width: 40px;
  height: 15px;
  background-color: #e3d1f8;
  border-radius: 0%;

}

.circle4 {
  width: 40px;
  height: 15px;
  background-color: #c1d0b5;
  border-radius: 0%;
}

.text {
  color: black;
}

.loading {
  position: fixed;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
  background: -webkit-radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

.state-label {
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  /* Adjust font size as needed */
  color: #333;
  /* Text color */
  background: none;
  border: none;
}

.leaflet-interactive {
  stroke: red !important;
  stroke-width: 5px !important;
  fill: yellow !important;
  fill-opacity: 0.5 !important;
}

/* Animation */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=basicInformation_old.css.map */
.leaflet-container {
  background: none !important;
}


.backButton {
  background: #082C7C;
  border: none;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  padding: 6px 9px 6px 7px;
  display: flex;

  svg {
    height: 16px;
    width: 16px;
    margin-right: 5px;
  }
}

.mapindia {
  width: 100%;
}

.map-dropdown {
  position: absolute;
  right: 0;
   z-index: 1;

  @media (max-width: 1599px) and (min-width: 1400px) {
    right: 4%;
  }

  @media (max-width: 1399px) and (min-width: 1024px) {
    right: 5%;
  }

  @media (max-width: 767px) {
    position: static;
  }


  select {
    width: 200px;
    float: right;
    margin-right: 15px;
    // margin-top: 12px;
    appearance: auto;
    cursor: pointer;
    // height: 38px;

    @media (max-width: 1599px) and (min-width: 1400px) {
      margin-right: 0px;
      width: 200px;
      // height: 30px;
      // margin-top: 10px;
      font-size: 14px;
    }

    @media (max-width: 1399px) and (min-width: 1024px) {
      margin-right: 0px;
      width: 200px;
      // margin-top: 10px;
      // height: 30px;
      font-size: 12px;
    }

    @media (max-width: 767px) {
      width: 150px;
    }
  }
}

.map-back-btn {
  position: absolute;
  right: 38%;
  top: 21%;
  z-index: 9999;

  @media (max-width: 1599px) and (min-width: 1400px) {
    right: 34%;
    top: 20.7%;

    .backButton {
      font-size: 12px;
      padding: 5px 9px 5px 7px;
    }
  }

  @media (max-width: 1399px) and (min-width: 1024px) {
    right: 36%;
    top: 22.4%;

    .backButton {
      font-size: 12px;
      padding: 5px 9px 5px 7px;
    }
  }


}

.custom-tooltip {
  transform: scale(1.0);
  transform-origin: center;
  border: 2px solid red;
  max-width: 255px;
  padding: 10px;
  font-size: 10px;
  background-color: #303841;
  ;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  color: white;
  font-weight: 400;
  border: none;

}

.tooltip-content {
  font-size: 16px;

}

.tooltip-content-text {
  color: #ff9800;
}

.map {
  transform: scale(1.7);
  transform-origin: center;
  overflow: hidden;
  z-index: 0;
  pointer-events: none;
  /* Prevent the map from blocking other UI elements */
  height: 71vh;
  width: 100%;

  @media(min-width: 1921px) {
    transform: scale(1.8);
    height: 71vh;
    width: 100%;
  }

  @media (max-width: 1599px) and (min-width: 1400px) {
    transform: scale(1.25);
    height: 67.5vh;
  }

  @media (max-width: 1399px) and (min-width: 1024px) {
    transform: scale(.96);
    height: 71vh;
  }

  @media (max-width: 767px) {
    transform: scale(.9);
    height: 60vh;
  }


}

.map-interactive {
  pointer-events: auto;
  /* Restore pointer events for interactive map elements */
}

.map-overley {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  z-index: 1000;
  height: 82vh;
  width: 100%;

  @media(max-width: 1599px) and (min-width: 1400px) {
    height: 80vh;
  }

  @media (max-width: 1399px) and (min-width: 1024px) {
    height: 77vh;
    top: 7%;
  }

}

.buttonWrapper {
  position: absolute;
  top: 1.8%;
  left: 19%;

  @media (max-width: 1599px) and (min-width: 1400px) {
    top: 1.8%;
    left: 20%;
  }

  @media (max-width: 1399px) and (min-width: 1024px) {
    top: 2%;
    left: 21%;
  }

  @media (max-width: 767px) {
    top: 1.2%;
    left: 5%;
  }

}

@media screen and (max-width: 768px) {
  .map-main-card .close-map-btn {
    display: none !important;
  }
}